// extracted by mini-css-extract-plugin
export var animateOpacity = "style-module--animateOpacity--25412";
export var header = "style-module--header--692ed";
export var itemBig = "style-module--itemBig--f24d0";
export var itemSmall = "style-module--itemSmall--daab4";
export var menu = "style-module--menu--a4b91";
export var menuItem = "style-module--menuItem--6af65";
export var menuItemRipple = "style-module--menuItemRipple--46b33";
export var name = "style-module--name--40c47";
export var nameHome = "style-module--nameHome--d0a1b";
export var selectedDecorator = "style-module--selectedDecorator--5a9ce";
export var transparentHeader = "style-module--transparentHeader--4e0c9";